<template>
    <v-container fluid>
        <v-row style="padding-bottom: 20px;">
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="10"
                md="8"
                sm="6"
            >
                <h3>Open Work Orders</h3>
            </v-col>
            <v-col 
                style="text-align: right;"
                cols="6"
                lg="2"
                md="4"
                sm="6"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            @click="showDialogOptions = true"
                        >
                            Export PDF
                        </v-btn>
                    </template>
                    <span>Export Report to PDF</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        label="Quick Search"
                        prepend-inner-icon="mdi mdi-magnify"
                        outlined
                        single-line
                        @click:prepend-inner="getRegisters"
                        :single-expand="true"
                        show-expand
                        @click:row="showDetails"
                        dense
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    :single-expand="true"
                    show-expand
                    no-data-text="No Record Found"
                    @click:row="showDetails"
                >
                    <template v-slot:item.id="{ item }">
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">
                                <a @click="goToWorkOrder(item.id)" v-on="on" target="_blank">
                                    {{ item.id }}
                                </a>
                            </template>
                            <span>Open Work order</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.projectDescription="{ item }">
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">
                                <a @click="goToServiceDetails(item.idService)" v-on="on" target="_blank">
                                    {{ item.projectDescription }}
                                </a>
                            </template>
                            <span>Job Details</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.dateRegisterFormatted="{ item }">
                        <label :style="item.targetDateExpired == 1 ? 'color: var(--color__red) !important; ' : ''">
                            {{ item.dateRegisterFormatted }}
                        </label>
                    </template>

                    <template v-slot:item.targetDateFormatted="{ item }">
                        <label :style="item.targetDateExpired == 1 ? 'color: var(--color__red) !important; ' : ''">
                            {{ item.targetDateFormatted }}
                        </label>
                    </template>

                    <template v-slot:item.projectStatusDescription="{ item }">
                        <label :style="item.targetDateExpired == 1 ? 'color: var(--color__red) !important; ' : ''">
                            {{ item.projectStatusDescription }}
                        </label>
                    </template>

                    <template v-slot:item.responsibleName="{ item }">
                        <label :style="item.targetDateExpired == 1 ? 'color: var(--color__red) !important; ' : ''">
                            {{ item.responsibleName }}
                        </label>
                    </template>

                    <template v-slot:item.age="{ item }">
                        <label :style="item.targetDateExpired == 1 ? 'color: var(--color__red) !important; ' : ''">
                            {{ item.age }}
                        </label>
                    </template>

                    <template v-slot:item.statusDescription="{ item }">
                        <v-chip
                            :color="getStatusColor(item)"
                            outlined
                            dark
                        >
                            {{ item.statusDescription }}
                        </v-chip>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <div style="width: 100%; margin: 10px;">
                                <h3>Scope of Work</h3>
                                <br />
                                <span>{{ item.scopeOfWork }}</span>
                            </div>
                        </td>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-dialog
            v-model="showDialogOptions"
            transition="dialog-top-transition"
            persistent
            width="500"
            :fullscreen="$vuetify.breakpoint.mobile"
        >

            <v-card>
                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                    Options
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-switch 
                                v-model="showScopeOfWork"
                                label="Show scope of work?"
                                :value=true
                                color="var(--color__main)"
                            >
                            </v-switch>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <div style="width: 100%; text-align: center;">
                        <v-btn
                            class="mx-2 dialogButtonDismiss v-btn-large"
                            style=""
                            outlined
                            @click="showDialogOptions = false"
                        >
                            Dismiss
                        </v-btn>
                        <v-btn
                            class="mx-2 v-btn-large"
                            outlined
                            @click="downloadFile(URL_REPORT_WORK_ORDER, showScopeOfWork == null ? false : showScopeOfWork, 'application/pdf', 'OpenWorkOrders.pdf');"
                        >
                            Export
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import { 
        URL_REPORT_WORK_ORDER
    } from "@/utilities/Enums";

    export default ({

        mixins: [Helpers],

        data: () => ({

            loading: false,

            filter: {
                fastSearch: ''
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: "Project", value: "projectDescription", sortable: true },
                { text: "Created On", value: "dateRegisterFormatted", sortable: true },
                { text: "Target Date", value: "targetDateFormatted", sortable: true },
                { text: "Phase", value: "projectStatusDescription", sortable: true },
                { text: "Assign To", value: "responsibleName", sortable: true },
                { text: "Deadline (days)", value: "age", sortable: true },
                { text: "Status", value: "statusDescription", sortable: true }
            ],

            options: { rowsPerPage: 10, page: 1 },

            listWorkOrder: [],
            listWorkOrderFiltered: [],

            showDialogOptions: false,
            showScopeOfWork: false,

            URL_REPORT_WORK_ORDER: URL_REPORT_WORK_ORDER
        }),


        computed: {

            filteredSearch: function() {
                return this.listWorkOrderFiltered.filter((workOrderFilter) => {
                    
                    const filter = this.filter.fastSearch.toLowerCase();

                    const dateRegisterFormatted = workOrderFilter.dateRegisterFormatted.toLowerCase().match(filter)
                    const projectDescription = workOrderFilter.projectDescription.toLowerCase().match(filter)
                    const projectStatusDescription = workOrderFilter.projectStatusDescription.toLowerCase().match(filter)
                    const targetDateFormatted = workOrderFilter.targetDateFormatted.toLowerCase().match(filter)
                    const responsibleName = workOrderFilter.responsibleName.toLowerCase().match(filter)
                    const id = workOrderFilter.id.toString().match(filter);

                    if(dateRegisterFormatted != null) { return dateRegisterFormatted; }
                    else if(projectDescription != null) { return projectDescription; }
                    else if(projectStatusDescription != null) { return projectStatusDescription; }
                    else if(targetDateFormatted != null) { return targetDateFormatted; }
                    else if(responsibleName != null) { return responsibleName; }
                    else { return id; }
                });
            },
        },

        methods: {

            getStatusColor (item) {

                let color = "";

                switch (item.status) {

                    case 0:
                        color = 'var(--color__cinza_escuro)';
                        break;

                    case 1:
                        color = 'var(--color__status_pending)';
                        break;
                }

                if (item.targetDateExpired == 1) {
                    color = 'var(--color__red)';
                }

                return color
            },
            
            goToWorkOrder(id) {
                this.$router.push({ name: "workOrderForm", params: {id: id} });
            },

            goToServiceDetails(id) {
                this.$router.push({ name: "serviceDetails", params: {id: id} });
            },

            async getRegisters() {
                this.listWorkOrder = await this.$store.dispatch("workOrderModule/ListOpen");
                this.listWorkOrderFiltered = [...this.listWorkOrder];
            },

            showDetails(_, item2) {
                item2.expand(true);
            },
        },

        async created() {
            this.showLoading();
            await this.getRegisters();
            this.hideLoading();
        }
    })
</script>

<style>

    table > tbody > tr {
        cursor: pointer !important;
    }

</style>